import { com } from '@eidu/entity'
import EntityWithLabel from './EntityWithLabel'
import Entity = com.eidu.sharedlib.entity.Entity
import EntityId = com.eidu.sharedlib.entity.EntityId

class EntityWithLabelAndRelated {
  constructor(
    readonly entity: Entity,
    readonly primaryLabelText: string,
    readonly secondaryLabelText: string | undefined,
    readonly referencedEntities: readonly EntityWithLabel[],
    relatedEntityIds: readonly EntityId[]
  ) {
    this.relatedEntityIds = new Set(relatedEntityIds.map((it) => it.asString()))
  }

  private readonly relatedEntityIds: Set<string>

  getReferencedEntity = (entityId: EntityId): EntityWithLabel | undefined =>
    this.referencedEntities.find((it) => it.entity.id.equals(entityId))

  isRelated = (entityId: EntityId) => this.relatedEntityIds.has(entityId.asString())

  hashCode = (): number => this.entity.hashCode()

  equals = (other: unknown): boolean => {
    if (other instanceof EntityWithLabelAndRelated) return this.entity.equals(other.entity)
    else return false
  }
}

export default EntityWithLabelAndRelated
