import { useParams } from 'react-router-dom'
import React, { useContext } from 'react'
import { com } from '@eidu/entity'
import { EntityTypesContext } from '../../../io/context/EntityTypes'
import UploadPage from '../UploadPage'
import entityTypeIdFromString = com.eidu.sharedlib.entity.type.entityTypeIdFromString

const ImportPageRoute = () => {
  const params = useParams()
  const entityTypeRepository = useContext(EntityTypesContext)

  return (
    <UploadPage
      entityTypeId={params.entityTypeId?.let((it) => entityTypeIdFromString(it))}
      entityTypeRepository={entityTypeRepository}
    />
  )
}

export default ImportPageRoute
