import { Alert, IconButton, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import { com, kotlin, Nullable } from '@eidu/entity'
import React, { useState } from 'react'
import { HelpOutline } from '@mui/icons-material'
import FieldData from '../../domain/entity/FieldData'
import FieldInput from './FieldInput'
import LabelInput from './label/LabelInput'
import TypeValidationError = com.eidu.sharedlib.entity.validation.TypeValidationError
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import KtSet = kotlin.collections.KtSet

type FieldWithErrors = FieldData & { errors: readonly TypeValidationError[] | undefined }

const fieldWithErrors = (field: FieldData, errors: readonly TypeValidationError[] | undefined): FieldWithErrors => ({
  ...field,
  errors,
})

const fieldsWithErrors = (
  fields: readonly FieldData[],
  errors: Nullable<readonly TypeValidationError[]>[] | undefined
): FieldWithErrors[] => fields.map((field, index) => fieldWithErrors(field, errors?.[index] ?? undefined))

export type LabelComponentProps = {
  fields: readonly FieldData[]
  primaryLabel: string
  primaryLabelErrors?: readonly TypeValidationError[]
  setPrimaryLabel: (label: string) => void
  secondaryLabel: string
  secondaryLabelErrors?: readonly TypeValidationError[]
  setSecondaryLabel: (label: string) => void
  labelEditable: boolean
}

const LabelComponent = ({
  fields,
  primaryLabel,
  primaryLabelErrors,
  setPrimaryLabel,
  secondaryLabel,
  secondaryLabelErrors,
  setSecondaryLabel,
  labelEditable,
}: LabelComponentProps) => {
  const [showInstructions, setShowInstructions] = useState(false)

  const availablePrimaryLabelFields: FieldData[] = fields.filter(
    (field) => field.field.type === FieldType.Text || field.field.type === FieldType.Number
  )

  const availableSecondaryLabelFields: FieldData[] = fields.filter(
    (field) =>
      field.field.type === FieldType.Text ||
      field.field.type === FieldType.Number ||
      field.field.type === FieldType.Reference
  )

  const primaryLabelErrorString =
    (!!primaryLabelErrors && primaryLabelErrors.length > 0 && primaryLabelErrors.map((it) => it.message).join(' • ')) ||
    undefined
  const secondaryLabelErrorString =
    (!!secondaryLabelErrors &&
      secondaryLabelErrors.length > 0 &&
      secondaryLabelErrors.map((it) => it.message).join(' • ')) ||
    undefined

  return (
    <>
      <Typography variant="h6">
        Label
        <IconButton color="primary" onClick={() => setShowInstructions(!showInstructions)}>
          <HelpOutline sx={{ fontSize: 18 }} />
        </IconButton>
      </Typography>

      {showInstructions && (
        <Alert severity="info" onClose={() => setShowInstructions(false)}>
          <Typography>
            To create an entity label, use curly braces <code>{}</code> to include the values of specific fields in your
            text. The label consists of two lines:
            <ol>
              <li>
                <strong>First Line:</strong> You can reference fields of type <i>Text</i> or <i>Number</i>. Simply type
                the text you want, and where you need the value of a field, enclose the field name in curly braces. For
                example, <code>&laquo;{'Name: {First Name}'}&raquo;</code> will display <i>&laquo;Name: &raquo;</i>{' '}
                followed by the value in the <code>First Name</code> field.
              </li>
              <li>
                <strong>Second Line:</strong> In addition to <i>Text</i> and <i>Number</i> fields, you may also
                reference <i>Reference</i> type fields here. Write your text, and use curly braces to include fields as
                needed. For example, <code>&laquo;{'{Linked Entity}'}&raquo;</code> would display the reference field{' '}
                <code>Linked Entity</code>.
              </li>
            </ol>
          </Typography>

          <Typography>
            Ensure your field names match exactly, including capitalization, and enclose each field name within{' '}
            <code>{'{}'}</code> to ensure it renders correctly.
          </Typography>
        </Alert>
      )}
      {availablePrimaryLabelFields.length === 0 && (
        <Typography>
          <strong>
            <br />
            No suitable fields found.
          </strong>
        </Typography>
      )}

      <LabelInput
        label={primaryLabel}
        setLabel={setPrimaryLabel}
        title="First Line (required)"
        availableFields={availablePrimaryLabelFields}
        editable={labelEditable}
        errors={primaryLabelErrorString}
      />

      <LabelInput
        label={secondaryLabel}
        setLabel={setSecondaryLabel}
        title="Second Line (optional)"
        availableFields={availableSecondaryLabelFields}
        editable={labelEditable}
        errors={secondaryLabelErrorString}
      />
    </>
  )
}

export type FieldTypeInputProps = {
  entityTypeId: EntityTypeId | undefined
  idPrefix: string
  entityTypeName: string
  entityTypeNameErrors?: readonly TypeValidationError[]
  setEntityTypeName: (value: string) => void
  fields: readonly FieldData[]
  fieldErrors?: Nullable<readonly TypeValidationError[]>[]
  setFields: (fieldTypes: readonly FieldData[]) => Promise<void>
  primaryLabel: string
  primaryLabelErrors?: readonly TypeValidationError[]
  setPrimaryLabel: (label: string) => void
  secondaryLabel: string
  secondaryLabelErrors?: readonly TypeValidationError[]
  setSecondaryLabel: (label: string) => void
  labelEditable: boolean
  availableEntityTypes: readonly EntityType[]
  indexBeingUpdated: number | null
  setIndexBeingUpdated: (index: number | null) => void
}

const FieldTypeInput = ({
  entityTypeId,
  idPrefix,
  entityTypeName,
  entityTypeNameErrors,
  setEntityTypeName,
  fields,
  fieldErrors,
  setFields,
  primaryLabel,
  primaryLabelErrors,
  setPrimaryLabel,
  secondaryLabel,
  secondaryLabelErrors,
  setSecondaryLabel,
  labelEditable,
  availableEntityTypes,
  indexBeingUpdated,
  setIndexBeingUpdated,
}: FieldTypeInputProps) => {
  const handleFieldTypeChange = async (index: number, event: SelectChangeEvent) => {
    setIndexBeingUpdated(index)
    const updatedFields = [...fields].map((fieldTypeField: FieldData, fieldTypeIndex) =>
      fieldTypeIndex === index
        ? {
            ...fieldTypeField,
            field: fieldTypeField.field.withType(FieldType.valueOf(event.target.value)),
          }
        : fieldTypeField
    )
    await setFields(updatedFields)
    setIndexBeingUpdated(null)
  }

  const handleRequiredChange = async (index: number) => {
    setIndexBeingUpdated(index)
    const updatedFields = [...fields].map((fieldTypeField: FieldData, fieldTypeIndex) =>
      fieldTypeIndex === index
        ? {
            ...fieldTypeField,
            field: fieldTypeField.field.withRequired(!fieldTypeField.field.required),
          }
        : fieldTypeField
    )
    await setFields(updatedFields)
    setIndexBeingUpdated(null)
  }

  const handleReferenceTypesChange = async (index: number, referenceTypes: readonly EntityTypeId[]) => {
    setIndexBeingUpdated(index)
    const updatedFields = [...fields].map((fieldTypeField: FieldData, fieldTypeIndex) =>
      fieldTypeIndex === index
        ? {
            ...fieldTypeField,
            field: fieldTypeField.field.withValidReferenceTypes(KtSet.fromJsSet(new Set(referenceTypes))),
          }
        : fieldTypeField
    )
    await setFields(updatedFields)
    setIndexBeingUpdated(null)
  }

  const hasValidReferenceTypes = (field: FieldData | undefined) =>
    field !== undefined && field.field.validReferenceTypes?.asJsReadonlySetView()?.let((it) => it.size > 0)

  return (
    <Stack spacing={3}>
      <Typography variant="h4">Upload entities {entityTypeName && <span>of type {entityTypeName}</span>}</Typography>

      {!entityTypeId && fields.length > 0 && (
        <>
          <Typography variant="h5">Name</Typography>
          <TextField
            required
            label="Name"
            value={entityTypeName}
            onChange={(event) => setEntityTypeName(event.target.value)}
            error={!!entityTypeNameErrors && entityTypeNameErrors.length > 0}
          />
        </>
      )}

      {fields.length > 0 && <Typography variant="h5">Fields</Typography>}
      {fieldsWithErrors(fields, fieldErrors).map(({ field, editable, errors }, index) => (
        <Stack key={JSON.stringify([idPrefix, 'field', field.name])}>
          <FieldInput
            availableEntityTypes={availableEntityTypes}
            disabled={indexBeingUpdated !== null}
            editable={editable}
            fieldType={field.type}
            handleFieldTypeChange={(event) => handleFieldTypeChange(index, event)}
            handleReferenceTypesChange={(referenceTypes) => handleReferenceTypesChange(index, referenceTypes)}
            handleRequiredChange={() => handleRequiredChange(index)}
            idPrefix={idPrefix}
            name={field.name}
            referenceTypesError={!hasValidReferenceTypes(fields.at(index))}
            referenceTypes={
              fields
                .at(index)
                ?.field?.validReferenceTypes?.asJsReadonlySetView()
                ?.let((it) => Array.from(it)) || []
            }
            required={field.required}
            updating={indexBeingUpdated === index}
            errors={errors}
          />
        </Stack>
      ))}

      {fields.length > 0 && (
        <LabelComponent
          fields={fields}
          primaryLabel={primaryLabel}
          primaryLabelErrors={primaryLabelErrors}
          setPrimaryLabel={setPrimaryLabel}
          secondaryLabel={secondaryLabel}
          secondaryLabelErrors={secondaryLabelErrors}
          setSecondaryLabel={setSecondaryLabel}
          labelEditable={labelEditable}
        />
      )}
    </Stack>
  )
}

export default FieldTypeInput
