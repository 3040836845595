import { Availability } from '../Availability'
import areEqual from '../areEqual'
import adminAvailability from '../adminAvailability'

const prepareForEditing = (availabilities: readonly Availability[], organizationId: string) =>
  availabilities.length && areEqual(adminAvailability(organizationId), availabilities[0])
    ? availabilities
    : [adminAvailability(organizationId), ...availabilities]

export default prepareForEditing
