import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { com } from '@eidu/entity'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackBar } from '../../components/SnackBarProvider'
import EntityForm from '../../components/entity/EntityForm'
import LoadingOverlay from '../../components/LoadingOverlay'
import { EntityTypesContext } from '../../io/context/EntityTypes'
import DraftFieldValue, {
  draftFieldValuesToFieldValues,
  fieldValueToDraftFieldValue,
  fieldValueToString,
} from '../../domain/entity/DraftFieldValue'
import EqualityHashMap from '../../util/EqualityHashMap'
import { requireNotUndefinedOrNull } from '../../util/require'
import createEntityToCreate from './createEntityToCreate'
import hasFieldError from '../../domain/entity/hasFieldError'
import { logException } from '../../util/Logging'
import useEntityRepository from '../../io/useEntityRepository'
import useEntitiesOfType from '../../io/useEntitiesOfType'
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import Entity = com.eidu.sharedlib.entity.Entity
import entityIdFromString = com.eidu.sharedlib.entity.entityIdFromString
import entityTypeIdFromString = com.eidu.sharedlib.entity.type.entityTypeIdFromString
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityType = com.eidu.sharedlib.entity.type.EntityType

const getNonNullFieldsFromEntity = (entity: Entity): ReadonlyMap<FieldId, DraftFieldValue> =>
  new EqualityHashMap<FieldId, DraftFieldValue>(
    Array.from(entity.valuesByFieldId.asJsReadonlyMapView())
      .filter((it): it is [FieldId, FieldValue] => it[1] !== null)
      .map(([id, value]) => [id, fieldValueToDraftFieldValue(id, value)])
  )

export type ReadyCreateOrEditPageProps = {
  originalEntityId?: EntityId
  entityType: EntityType
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
}

const ReadyCreateOrEditPage = ({ originalEntityId, entityType, entityTypes }: ReadyCreateOrEditPageProps) => {
  const entityRepository = useEntityRepository(
    {
      typeId: entityType.id,
      types: entityTypes,
    },
    [entityType.id.asString(), entityTypes.size]
  )

  // Important: originalEntityId must not change between undefined and
  // not undefined, otherwise React's hook order will be messed up.
  const originalEntity = originalEntityId !== undefined ? entityRepository.useItem(originalEntityId) : undefined

  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()

  // Important: all use* need to run, so don't replace this by anything using short-circuiting!
  const error = [entityRepository.useError(), originalEntity === null ? 'Entity not found' : undefined]
    .find((it) => !!it)
    ?.let((it) => String(it))
  const loading = [entityRepository.useLoading(), originalEntityId !== undefined && originalEntity === undefined].some(
    (it) => it
  )

  const [submitting, setSubmitting] = useState(false)

  const [fields, setFields] = useState<ReadonlyMap<FieldId, DraftFieldValue>>(new Map())
  const updateField = (value: DraftFieldValue) => setFields((it) => new EqualityHashMap(it).set(value.fieldId, value))

  useEffect(() => {
    if (originalEntity) setFields(getNonNullFieldsFromEntity(originalEntity.entity))
  }, [originalEntity])

  const referenceableEntityTypeIds: EntityTypeId[] = entityType
    ? [
        ...new Set(
          Array.from(entityType.fields.asJsReadonlyArrayView()).flatMap((field) =>
            Array.from(field.validReferenceTypes?.asJsReadonlySetView() ?? [])
          )
        ),
      ]
    : []
  const referenceableEntityTypesById: Map<EntityTypeId, EntityType> = new EqualityHashMap(
    [...entityTypes].filter(([id]) => referenceableEntityTypeIds.some((it) => it.equals(id)))
  )
  const referenceableEntitiesByTypeId = useEntitiesOfType(referenceableEntityTypeIds, entityTypes)

  const valid = useMemo(() => {
    if (!entityType || !referenceableEntitiesByTypeId) return false
    return entityType.fields.asJsReadonlyArrayView().every((field) => {
      const fieldValue = fields.get(field.id) ?? undefined
      return !hasFieldError(field, fieldValue, referenceableEntitiesByTypeId)
    })
  }, [entityType, fields, referenceableEntitiesByTypeId])

  const submit = useCallback(async () => {
    setSubmitting(true)
    try {
      if (!originalEntity) {
        const localEntityType = requireNotUndefinedOrNull(entityType)
        try {
          const fieldValues = [...Array.from(fields.values())]
          await entityRepository.createEntity(createEntityToCreate(entityType.id, fieldValues))
          showSnackBar(`Created ${localEntityType.name}`, 'success')
          navigate(-1)
        } catch (e) {
          logException(e)
          showSnackBar(`Failed to create ${localEntityType.name}`, 'error')
        }
      } else {
        const localEntityType = requireNotUndefinedOrNull(entityType)
        const fieldValues = [...Array.from(fields.values())].filter((field) => {
          const originalValue = originalEntity.entity.valuesByFieldId.asJsReadonlyMapView().get(field.fieldId)
          return !originalValue || fieldValueToString(originalValue) !== field.value
        })
        try {
          await entityRepository.modifyEntity(originalEntity.entity.id, draftFieldValuesToFieldValues(fieldValues))
          showSnackBar(`${localEntityType.name} updated`, 'success')
          navigate(-1)
        } catch (e) {
          logException(e)
          showSnackBar(`Failed to update ${localEntityType.name}`, 'error')
        }
      }
    } finally {
      setSubmitting(false)
    }
  }, [entityRepository, navigate, entityType, originalEntity, fields, showSnackBar])

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const confirmDelete = async () => {
    setDeleteDialogOpen(false)
    const localEntityType = requireNotUndefinedOrNull(entityType)
    try {
      await entityRepository.deleteEntity(requireNotUndefinedOrNull(originalEntityId))
      showSnackBar(`Deleted ${localEntityType.name}`, 'success')
      navigate(-1)
    } catch (e) {
      logException(e)
      showSnackBar(`Failed to delete ${localEntityType.name}`, 'error')
    }
  }

  return (
    <>
      <Stack padding={3} spacing={3}>
        {entityType && referenceableEntitiesByTypeId && (
          <>
            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h4">{entityType.name}</Typography>
              {originalEntity && (
                <Button color="error" onClick={() => setDeleteDialogOpen(true)}>
                  Delete
                </Button>
              )}
            </Stack>
            <EntityForm
              fieldsById={fields}
              updateField={updateField}
              entityType={entityType}
              referenceableEntityTypesById={referenceableEntityTypesById}
              referenceableEntitiesByTypeId={referenceableEntitiesByTypeId}
            />
            <Stack direction="row" spacing={3} sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button variant="contained" onClick={submit} disabled={!valid}>
                Save
              </Button>
            </Stack>
          </>
        )}
        <LoadingOverlay isOpen={submitting || loading || !(error || entityType)} />
      </Stack>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            There may still be references to this {entityType?.name ?? 'entity'} in the system. Are you sure you want to
            delete this {entityType?.name ?? 'entity'}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const CreateOrEditPage = () => {
  const params = useParams()
  const originalEntityId = params.entityId?.let(entityIdFromString)
  const entityTypeId = entityTypeIdFromString(requireNotUndefinedOrNull(params.entityTypeId))

  const entityTypeRepository = useContext(EntityTypesContext)
  const entityTypes = entityTypeRepository.useAll()
  const entityType = entityTypeRepository.useItem(entityTypeId)
  const error = entityTypeRepository.useError()?.let((it) => String(it))

  return (
    <>
      {entityType && entityTypes && (
        <ReadyCreateOrEditPage originalEntityId={originalEntityId} entityType={entityType} entityTypes={entityTypes} />
      )}
      <LoadingOverlay isOpen={!(error || entityType)} />
    </>
  )
}

export default CreateOrEditPage
