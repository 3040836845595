import { Box, Button, Typography } from '@mui/material'
import React, { DetailedHTMLProps, useRef, useState } from 'react'

export type FileInputProps = {
  onChange(file: File): void
} & Omit<
  DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'type' | 'ref' | 'style'
>

const FileInput: React.FC<FileInputProps> = ({ onChange, ...rest }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [fileName, setFileName] = useState<string>('No file selected')

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setFileName(file.name)
      onChange(file)
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    } else {
      setFileName('No file selected')
    }
  }

  return (
    <Box display="flex" alignItems="center">
      <Button variant="contained" onClick={handleButtonClick}>
        Select File
      </Button>
      <Typography variant="body1" marginLeft={2}>
        {fileName}
      </Typography>
      <input {...rest} type="file" ref={inputRef} style={{ display: 'none' }} onChange={handleFileChange} />
    </Box>
  )
}

export default FileInput
