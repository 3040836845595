export type EqualityHashMapKey = {
  hashCode: () => number
  equals: (other: unknown) => boolean
}

class EqualityHashMap<K extends EqualityHashMapKey, V> implements Map<K, V> {
  readonly [Symbol.toStringTag] = '[object EqualityHashMap]'

  private readonly buckets: Map<number, [K, V][]>

  private numItems: number = 0

  constructor(source: readonly [K, V][] | ReadonlyMap<K, V> = []) {
    this.buckets = new Map<number, [K, V][]>()
    const entries = source instanceof Array ? source : Array.from(source)
    entries.forEach(([key, value]) => {
      this.set(key, value)
    })
  }

  private getOrCreateBucket = (key: K) => {
    const hashCode = key.hashCode()
    const bucket = this.buckets.get(hashCode)
    if (bucket === undefined) {
      const newBucket: [K, V][] = []
      this.buckets.set(hashCode, newBucket)
      return newBucket
    } else {
      return bucket
    }
  }

  private getBucket = (key: K) => this.buckets.get(key.hashCode())

  get size(): number {
    return this.numItems
  }

  set = (key: K, value: V): this => {
    const bucket = this.getOrCreateBucket(key)

    const existingEntry = bucket.find(([k, _]) => k.equals(key))
    if (existingEntry !== undefined) {
      existingEntry[1] = value
    } else {
      bucket.push([key, value])
      this.numItems += 1
    }

    return this
  }

  get = (key: K): V | undefined => {
    const bucket = this.getBucket(key)
    if (!bucket) {
      return undefined
    } else {
      return bucket.find(([k, _]) => k.equals(key))?.[1]
    }
  }

  has = (key: K): boolean => {
    const bucket = this.getBucket(key)
    if (!bucket) {
      return false
    } else {
      return bucket.some(([k, _]) => k.equals(key))
    }
  }

  delete = (key: K): boolean => {
    const bucket = this.getBucket(key)
    if (!bucket) {
      return false
    }

    const index = bucket.findIndex(([k, _]) => k.equals(key))
    if (index < 0) {
      return false
    } else {
      bucket.splice(index, 1)
      this.numItems -= 1
      if (bucket.length === 0) this.buckets.delete(key.hashCode())
      return true
    }
  }

  clear() {
    this.buckets.clear()
    this.numItems = 0
  }

  [Symbol.iterator](): IterableIterator<[K, V]> {
    return this.entries()
  }

  // prettier-ignore
  keys = (): IterableIterator<K> =>
    Array.from(this.buckets.values())
      .flatMap((bucket) =>
        bucket.map(([key, _]) => key)
      )[Symbol.iterator]()

  entries = (): IterableIterator<[K, V]> => Array.from(this.buckets.values()).flat()[Symbol.iterator]()

  // prettier-ignore
  values = (): IterableIterator<V> =>
    Array.from(this.buckets.values())
      .flatMap((bucket) =>
        bucket.map(([_, value]) => value)
      )[Symbol.iterator]()

  forEach = (callback: (value: V, key: K, map: Map<K, V>) => void): void =>
    Array.from(this.buckets.values()).forEach((bucket) => bucket.forEach(([key, value]) => callback(value, key, this)))
}

export default EqualityHashMap
