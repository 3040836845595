import {
  eiduKenyaOrganizationId,
  nigeriaKatsinaOrganizationId,
  nigeriaOyoOrganizationId,
  pakistanOrganizationId,
} from '../organization/Organizations'

type Role = { name: string; label: string }

const eiduKenyaAdminRole: Role = { name: 'Admin', label: 'Admin' }

const eiduKenyaRoles: readonly Role[] = [
  eiduKenyaAdminRole,
  { name: 'EiduCoach', label: 'EIDU Coach' },
  { name: 'EiduOfficer', label: 'EIDU Officer' },
  { name: 'EcdOfficer', label: 'ECD Officer' },
  { name: 'SubCountyEcdOfficer', label: 'Sub-county ECD Officer' },
]

const nigeriaKatsinaAdminRole: Role = { name: 'faf9d0e5-1945-473b-a6c0-03d88cb0d590', label: 'Admin' }

const nigeriaKatsinaRoles: readonly Role[] = [
  nigeriaKatsinaAdminRole,
  { name: 'a60e6fb3-f197-4822-93a8-7782e6d67e8d', label: 'Field Officer' },
]

const nigeriaOyoAdminRole: Role = { name: '595c73ee-9bcb-4f05-a0e1-2a1e2481857c', label: 'Admin' }

const nigeriaOyoRoles: readonly Role[] = [
  nigeriaOyoAdminRole,
  { name: '0950d510-6ad6-44a5-8ee6-e9d2dac69a5d', label: 'Field Officer' },
]

const pakistanAdminRole: Role = { name: 'f4707e8a-43da-4c96-b176-3a7381835b0f', label: 'Admin' }

const pakistanRoles: readonly Role[] = [
  pakistanAdminRole,
  { name: '7630f287-e0f8-4e4c-ad0e-c6facb314bfb', label: 'Field Officer' },
]

export const adminRole = (organizationId: string): Role | undefined => {
  switch (organizationId) {
    case eiduKenyaOrganizationId:
      return eiduKenyaAdminRole
    case nigeriaKatsinaOrganizationId:
      return nigeriaKatsinaAdminRole
    case nigeriaOyoOrganizationId:
      return nigeriaOyoAdminRole
    case pakistanOrganizationId:
      return pakistanAdminRole
    default:
      return undefined
  }
}

const roles = (organizationId: string): readonly Role[] => {
  switch (organizationId) {
    case eiduKenyaOrganizationId:
      return eiduKenyaRoles
    case nigeriaKatsinaOrganizationId:
      return nigeriaKatsinaRoles
    case nigeriaOyoOrganizationId:
      return nigeriaOyoRoles
    case pakistanOrganizationId:
      return pakistanRoles
    default:
      return []
  }
}

export default roles
