import { com, kotlin } from '@eidu/entity'
import { LoadedCsv, withFieldValidationErrors, withNameValidationErrors } from './UploadPageState'
import { stringToLabelLine } from '../../components/entity/label/LabelUtil'
import FieldData from '../../domain/entity/FieldData'
import LabelTypeValidationError = com.eidu.sharedlib.entity.validation.LabelTypeValidationError
import EntityTypeValidator = com.eidu.sharedlib.entity.validation.EntityTypeValidator
import KtList = kotlin.collections.KtList

export const withUpdatedNameValidation = (state: LoadedCsv) => {
  const result = EntityTypeValidator.validateNameString(state.entityTypeName)
  if (result != null) return withNameValidationErrors(state as LoadedCsv, result.asJsReadonlyArrayView())
  else return withNameValidationErrors(state as LoadedCsv, undefined)
}

export const withUpdatedFieldValidations = (state: LoadedCsv) => {
  const allFields = state.fields.map((it) => it.field)
  return allFields
    .map((field) => EntityTypeValidator.validateField(field, KtList.fromJsArray(allFields))?.asJsReadonlyArrayView())
    .let((results) =>
      results.every((it) => it === undefined || it === null)
        ? withFieldValidationErrors(state, undefined)
        : withFieldValidationErrors(state, results)
    )
}

const validateLabelLine = (line: string, fields: readonly FieldData[], allowReferences: boolean) => {
  const label = stringToLabelLine(line, fields)
  if (label === undefined) return [new LabelTypeValidationError('Label contains bad field references')]

  return EntityTypeValidator.validateLabelLineWithFieldsList(
    label,
    KtList.fromJsArray(fields.map((it) => it.field)),
    allowReferences
  )?.asJsReadonlyArrayView()
}

const withUpdatedPrimaryLabelValidation = (state: LoadedCsv): LoadedCsv => {
  if (state.primaryLabel === undefined || state.primaryLabel.trim() === '')
    return {
      ...state,
      validationErrors: {
        ...state.validationErrors,
        primaryLabelValidationErrors: undefined,
      },
    }

  return {
    ...state,
    validationErrors: {
      ...state.validationErrors,
      primaryLabelValidationErrors: validateLabelLine(state.primaryLabel, state.fields, false) || undefined,
    },
  }
}

const withUpdatedSecondaryLabelValidation = (state: LoadedCsv): LoadedCsv => {
  if (state.secondaryLabel === undefined || state.secondaryLabel.trim() === '')
    return {
      ...state,
      validationErrors: {
        ...state.validationErrors,
        secondaryLabelValidationErrors: undefined,
      },
    }

  return {
    ...state,
    validationErrors: {
      ...state.validationErrors,
      secondaryLabelValidationErrors: validateLabelLine(state.secondaryLabel, state.fields, true) || undefined,
    },
  }
}

export const withUpdatedLabelValidation = (state: LoadedCsv) =>
  withUpdatedSecondaryLabelValidation(withUpdatedPrimaryLabelValidation(state))

export const withUpdatedTypeValidations = (state: LoadedCsv) =>
  withUpdatedFieldValidations(withUpdatedLabelValidation(withUpdatedNameValidation(state)))
