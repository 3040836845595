import { com } from '@eidu/entity'
import BatchCache from './BatchCache'
import PageCache, { Page } from './PageCache'
import getEntityType from '../api/entity/type/getEntityType'
import getEntityTypes from '../api/entity/type/getEntityTypes'
import sortedBy from '../util/sort/sortedBy'
import patchEntityType, { PatchEntityTypeParameters } from '../api/entity/type/patchEntityType'
import postEntityType, { PostEntityTypeParameters } from '../api/entity/type/postEntityType'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityLabel = com.eidu.sharedlib.entity.label.EntityLabel
import Field = com.eidu.sharedlib.entity.field.Field
import PatchTypeResponse = com.eidu.sharedlib.entity.api.types.PatchTypeResponse
import EqualityHashMap from '../util/EqualityHashMap'

const PAGE_SIZE = 500

let globalToken: string = ''

export function setGlobalToken(token: string) {
  globalToken = token
}

type EntityTypeRepositoryParams = {
  requestFetchEntityType?: (id: EntityTypeId) => Promise<EntityType>
  requestFetchEntityTypesPage?: (pageIndex: number, pageSize: number) => Promise<Page<EntityTypeId, EntityType>>
  requestCreateEntityType?: (params: PostEntityTypeParameters) => Promise<EntityType>
  requestModifyEntityType?: (params: PatchEntityTypeParameters) => Promise<PatchTypeResponse>
}

class EntityTypeRepository extends PageCache<EntityTypeId, EntityType> {
  constructor({
    requestFetchEntityType,
    requestFetchEntityTypesPage,
    requestCreateEntityType,
    requestModifyEntityType,
  }: EntityTypeRepositoryParams = {}) {
    super(
      new BatchCache(requestFetchEntityType || EntityTypeRepository.fetchEntityTypeDefault),
      requestFetchEntityTypesPage || EntityTypeRepository.fetchEntityTypesPageDefault,
      PAGE_SIZE
    )
    this.doCreateEntityType = requestCreateEntityType || EntityTypeRepository.createEntityTypeDefault
    this.doModifyEntityType = requestModifyEntityType || EntityTypeRepository.modifyEntityTypeDefault
  }

  private readonly doCreateEntityType: (params: PostEntityTypeParameters) => Promise<EntityType>

  private readonly doModifyEntityType: (params: PatchEntityTypeParameters) => Promise<PatchTypeResponse>

  useSortedEntityTypes = () =>
    this.useAll()?.let((it) => sortedBy(Array.from(it.entries()), ([, value]) => value.name).map(([, value]) => value))

  createEntityType = async (
    name: string,
    fieldsToCreate: readonly Field[],
    label: EntityLabel
  ): Promise<EntityType> => {
    const response = await this.doCreateEntityType({
      name,
      fieldsToCreate,
      label,
      accessToken: globalToken,
    })
    await this.refreshAll()
    return response
  }

  modifyEntityType = async (id: EntityTypeId, fieldsToCreate: readonly Field[]): Promise<PatchTypeResponse> => {
    const response = await this.doModifyEntityType({
      id,
      fieldsToCreate,
      accessToken: globalToken,
    })
    await this.refreshAll()
    return response
  }

  private static fetchEntityTypeDefault = (id: EntityTypeId) => getEntityType({ id, accessToken: globalToken })

  private static fetchEntityTypesPageDefault = (pageIndex: number, pageSize: number) =>
    getEntityTypes({ pageIndex, pageSize, accessToken: globalToken }).then((response) => ({
      entries: new EqualityHashMap(
        response.documents.asJsReadonlyArrayView().map((it): [EntityTypeId, EntityType] => [it.id, it])
      ),
      totalCount: response.totalCount,
    }))

  private static createEntityTypeDefault = async (params: PostEntityTypeParameters): Promise<EntityType> =>
    postEntityType(params)

  private static modifyEntityTypeDefault = async (params: PatchEntityTypeParameters): Promise<PatchTypeResponse> =>
    patchEntityType(params)
}

export default EntityTypeRepository
