import { Box, CircularProgress, Stack, Tooltip, Typography } from '@mui/material'
import { LinkOff } from '@mui/icons-material'
import { com } from '@eidu/entity'
import React from 'react'
import EntityId = com.eidu.sharedlib.entity.EntityId

export type EntityReferenceProps = {
  id: EntityId | undefined
  label?: string
  secondaryLabel?: string
  typeName?: string
  state?: 'resolved' | 'unresolved' | 'pending'
}

const EntityReference = ({ id, label, secondaryLabel, typeName, state = 'resolved' }: EntityReferenceProps) => (
  <Tooltip
    title={
      <>
        {typeName && <Box sx={{ fontStyle: 'italic', fontWeight: 'bold' }}>{typeName}</Box>}
        <Box>
          {state === 'unresolved' && 'The referenced entity could not be found: '}
          {state === 'pending' && 'Loading '}
          {id?.asString() ?? '?'}
        </Box>
        {state === 'resolved' && !label && (
          <Box sx={{ marginTop: 1 }}>
            Showing the entity&apos;s internal identifier because we could not find a more suitable label.
          </Box>
        )}
      </>
    }
  >
    <Stack>
      {state === 'resolved' && label && (
        <>
          <Typography variant="body1" color="primary">
            {label}
          </Typography>
          {secondaryLabel && (
            <Typography variant="body2" color="gray">
              {secondaryLabel}
            </Typography>
          )}
        </>
      )}
      {state === 'resolved' && !label && (
        <Typography color="primary" sx={{ fontStyle: 'italic' }}>
          {id?.asString() ?? '?'}
        </Typography>
      )}
      {state === 'unresolved' && (
        <Stack direction="row">
          <LinkOff color="error" />
          <Typography color="error" sx={{ fontStyle: 'italic' }}>
            {id?.asString() ?? '?'}
          </Typography>
        </Stack>
      )}
      {state === 'pending' && <CircularProgress size="1.5em" />}
    </Stack>
  </Tooltip>
)

export default EntityReference
