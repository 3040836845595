import { AvailabilityParameter } from './AvailabilityParameter'

const availabilityParameterDisplayValues = (
  parameterType: AvailabilityParameter['type'],
  values: readonly string[]
) => {
  switch (parameterType) {
    case 'Any':
      return ['All']
    case 'OneOf':
      return values
    default: {
      const unused: never = parameterType
      return values
    }
  }
}

export default availabilityParameterDisplayValues
