import EqualityHashMap, { EqualityHashMapKey } from './EqualityHashMap'

class PeekablePromise<V> {
  private readonly promise: Promise<V>

  private value: V | undefined

  private constructor(readonly p: Promise<V>) {
    this.promise = p
  }

  peek = () => this.value

  get = () => this.promise

  static create<V>(p: Promise<V>): PeekablePromise<V> {
    const peekable = new PeekablePromise(p)

    p.then((value) => {
      peekable.value = value
      return value
    }).catch(() => undefined)

    return peekable
  }

  static createFromMap<K extends EqualityHashMapKey, V>(
    p: Promise<ReadonlyMap<K, V>>,
    keys: readonly K[]
  ): ReadonlyMap<K, PeekablePromise<V>> {
    const peekables = new EqualityHashMap<K, PeekablePromise<V>>(
      keys.map((key) => {
        const newPromise = p.then((map) => map.get(key)!)
        newPromise.catch(() => undefined)
        const peekable = new PeekablePromise<V>(newPromise)
        return [key, peekable]
      })
    )

    p.then((map) => {
      keys.forEach((key) => {
        peekables.get(key)!.value = map.get(key)
      })
      return map
    }).catch(() => undefined)

    return peekables
  }
}

export default PeekablePromise
