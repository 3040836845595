import actions from './Action'
import { Availability } from './Availability'
import { adminRole } from '../../user/roles'

const adminAvailability = (organizationId: string): Availability => {
  const admin = adminRole(organizationId)?.name
  return {
    actions,
    role: { type: 'OneOf', names: admin ? [admin] : [] },
    program: { type: 'Any' },
  }
}

export default adminAvailability
