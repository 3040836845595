import { createContext, ReactNode, useContext, useMemo } from 'react'

type RefetchableDataContextActions = {
  refetch: () => void
}

const RefetchableDataContext = createContext({} as RefetchableDataContextActions)

interface RefetchableDataContextProviderProps {
  children: ReactNode
  refetch: () => void
}

const RefetchableDataProvider = ({ children, refetch }: RefetchableDataContextProviderProps) => {
  const refresh = useMemo(() => ({ refetch }), [])
  return <RefetchableDataContext.Provider value={refresh}>{children}</RefetchableDataContext.Provider>
}

export const useRefetch = (): RefetchableDataContextActions => {
  const context = useContext(RefetchableDataContext)

  if (!context) {
    throw new Error('useRefetch must be used within a RefetchableDataProvider')
  }

  return context
}

export default RefetchableDataProvider
