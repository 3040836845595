import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import EntityId = com.eidu.sharedlib.entity.EntityId

type DeleteEntityParameters = { id: EntityId; accessToken: string }

const deleteEntity = async ({ id, accessToken }: DeleteEntityParameters) => {
  const response = await fetch(`${apiBaseUrl}/entities/${id.asString()}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
    method: 'DELETE',
  })
  if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from deleteEntity endpoint`)
}

export default deleteEntity
