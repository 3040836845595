import apiBaseUrl from './baseUrl'
import bearerTokenHeader from '../authorization/bearerTokenHeader'
import FormConfigurationToCreate from '../../domain/form/FormConfigurationToCreate'

type PostFormConfigurationParameters = {
  organizationId: string
  accessToken: string
  formConfigurationToCreate: FormConfigurationToCreate
}

const postFormConfiguration = async ({
  organizationId,
  accessToken,
  formConfigurationToCreate,
}: PostFormConfigurationParameters) => {
  const response = await fetch(`${apiBaseUrl}/organizations/${organizationId}/forms`, {
    method: 'POST',
    headers: bearerTokenHeader(accessToken),
    body: JSON.stringify({ configuration: formConfigurationToCreate }),
  })
  if (response.status !== 200)
    throw Error(`Unexpected response code ${response.status} from postFormConfiguration endpoint`)
}

export default postFormConfiguration
