import { com, outcome } from '@eidu/entity'
import FieldData from './FieldData'
import ParsingValidationError = com.eidu.sharedlib.entity.validation.ParsingValidationError
import ValidationError = com.eidu.sharedlib.entity.validation.ValidationError
import OutcomeDto = outcome.OutcomeDto
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import entityIdFromStringOrNull = com.eidu.sharedlib.entity.entityIdFromStringOrNull
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import TextValue = com.eidu.sharedlib.entity.field.TextValue
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import NumberValue = com.eidu.sharedlib.entity.field.NumberValue
import ReferenceValue = com.eidu.sharedlib.entity.field.ReferenceValue
import MissingValueValidationError = com.eidu.sharedlib.entity.validation.MissingValueEntityValidationError
import EqualityHashMap from '../../util/EqualityHashMap'

type ValidationError1D = readonly ValidationError[]
type ValidationError2D = readonly ValidationError1D[]
type ValidationError3D = readonly ValidationError2D[]

export const fieldValueFromString = (value: string, type: FieldType): OutcomeDto<ValidationError, FieldValue> => {
  switch (type) {
    case FieldType.Text:
      return new outcome.Success(new TextValue(value))
    case FieldType.Number: {
      const number = Number(value)
      if (value.trim().length === 0 || Number.isNaN(number))
        return new outcome.Error(new ParsingValidationError(`Invalid number: '${value}'`))
      else return new outcome.Success(new NumberValue(number))
    }
    case FieldType.Reference: {
      const id = entityIdFromStringOrNull(value)
      if (!id) return new outcome.Error(new ParsingValidationError(`Invalid entity id: '${value}'`))
      else return new outcome.Success(new ReferenceValue(id))
    }
    default:
      return new outcome.Error(new ParsingValidationError(`Unsupported field type: ${type}`))
  }
}

export const fieldValuesFromStrings = (
  values: readonly string[],
  fields: readonly FieldData[]
): OutcomeDto<ValidationError2D, Map<FieldId, FieldValue>> => {
  if (values.length !== fields.length) throw Error('Number of values mismatch')

  const fieldValues = values.map((value, index) => {
    const { field } = fields[index]
    if (field.required && value.length === 0)
      return new outcome.Error(new MissingValueValidationError(`Value required for field ${field.name}`))
    else return fieldValueFromString(value, field.type)
  })
  if (fieldValues.some((it) => it instanceof outcome.Error)) {
    return new outcome.Error(
      fieldValues.map((it) => (it instanceof outcome.Error ? [it.value as ValidationError] : []))
    )
  } else {
    return new outcome.Success(
      new EqualityHashMap(
        fieldValues.map((valueOutcome, index) => [
          fields[index].field.id,
          (valueOutcome as outcome.Success<FieldValue>).value,
        ])
      )
    )
  }
}

export const fieldValuesFromStringsBatch = (
  values: readonly (readonly string[])[],
  fields: readonly FieldData[]
): OutcomeDto<ValidationError3D, readonly Map<FieldId, FieldValue>[]> => {
  const results = values.map((row) => fieldValuesFromStrings(row, fields))

  if (results.some((it) => it instanceof outcome.Error)) {
    const noErrors: ValidationError[] = Array(fields.length).fill([])
    return new outcome.Error(results.map((it) => (it instanceof outcome.Error ? it.value : noErrors)))
  } else {
    return new outcome.Success(results.map((it) => (it as outcome.Success<Map<FieldId, FieldValue>>).value))
  }
}
