import { com, kotlin } from '@eidu/entity'
import EqualityHashMap from '../../util/EqualityHashMap'
import { logException } from '../../util/Logging'
import getReferencedEntityIds from './getReferencedEntityIds'
import { isNotUndefinedOrNull } from '../../util/predicates'
import EntityWithLabelAndRelated from './EntityWithLabelAndRelated'
import EntityWithLabel from './EntityWithLabel'
import Entity = com.eidu.sharedlib.entity.Entity
import PaginatedResponseWithRelated = com.eidu.sharedlib.entity.api.common.PaginatedResponseWithRelated
import getPrimaryLabelText = com.eidu.sharedlib.entity.label.getPrimaryText
import getSecondaryLabelText = com.eidu.sharedlib.entity.label.getSecondaryText
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityId = com.eidu.sharedlib.entity.EntityId
import getRelatedEntities = com.eidu.sharedlib.entity.util.getRelatedEntities
import KtList = kotlin.collections.KtList

export const getEntitiesFromResponse = (
  response: PaginatedResponseWithRelated<Entity>,
  types: ReadonlyMap<EntityTypeId, EntityType>
): [EntityId, EntityWithLabelAndRelated][] => {
  const relatedEntitiesById =
    response.relatedDocuments?.let(
      (related) => new EqualityHashMap(Array.from(related.asJsReadonlyArrayView()).map((it) => [it.id, it]))
    ) ?? new Map<EntityId, Entity>()

  const getEntityWithLabel = (id: EntityId): EntityWithLabel | undefined => {
    const entity = relatedEntitiesById.get(id)
    const type = entity?.let((it) => types.get(it.typeId))
    if (entity && type)
      return {
        entity,
        primaryLabelText: getPrimaryLabelText(type.label, entity),
        secondaryLabelText:
          getSecondaryLabelText(
            type.label,
            entity,
            (typeId: EntityTypeId) => types.get(typeId),
            (entityId: EntityId) => relatedEntitiesById.get(entityId)
          ) ?? undefined,
      }
    else return undefined
  }

  const getRelated = (entity: Entity): [EntityId, EntityWithLabelAndRelated] => {
    const type = types.get(entity.typeId)
    if (!type) {
      if (types.size > 0)
        logException(`Entity type ${entity.typeId.asString()} for entity ${entity.id.asString()} not found`)
      return [entity.id, new EntityWithLabelAndRelated(entity, '?', undefined, [], [])]
    }

    const referencedEntities = getReferencedEntityIds(entity)
      .map((it) => getEntityWithLabel(it))
      .filter(isNotUndefinedOrNull)

    const relatedEntityIds = Array.from(
      getRelatedEntities(
        KtList.fromJsArray([entity]),
        (typeIds) =>
          KtList.fromJsArray(
            Array.from(typeIds.asJsReadonlyArrayView())
              .map((typeId) => types.get(typeId))
              .filter(isNotUndefinedOrNull)
          ),
        (entityIds) =>
          KtList.fromJsArray(
            Array.from(entityIds.asJsReadonlyArrayView())
              .map((entityId) => relatedEntitiesById.get(entityId))
              .filter(isNotUndefinedOrNull)
          )
      )
        .asJsReadonlyArrayView()
        .map((it) => it.id)
    )

    return [
      entity.id,
      new EntityWithLabelAndRelated(
        entity,
        getPrimaryLabelText(type.label, entity),
        getSecondaryLabelText(
          type.label,
          entity,
          (typeId: EntityTypeId) => types.get(typeId),
          (entityId: EntityId) => relatedEntitiesById.get(entityId)
        ) ?? undefined,
        referencedEntities,
        relatedEntityIds
      ),
    ]
  }

  return response.documents.asJsReadonlyArrayView().map(getRelated)
}

const getEntitiesFromResponses = (
  responses: readonly PaginatedResponseWithRelated<Entity>[],
  types: ReadonlyMap<EntityTypeId, EntityType>
): Map<EntityId, EntityWithLabelAndRelated> =>
  new EqualityHashMap(responses.map((it) => getEntitiesFromResponse(it, types)).flat())

export default getEntitiesFromResponses
