import PaginatedResponse from './PaginatedResponse'

const fetchAllPages = async <T>(
  fetchSinglePage: (pageIndex: number) => Promise<PaginatedResponse<T>>
): Promise<readonly T[]> => {
  const firstPage = await fetchSinglePage(0)
  const firstPageDocuments = firstPage.documents
  const { totalCount } = firstPage
  if (totalCount <= firstPageDocuments.length) return firstPageDocuments

  const additionalPages = await Promise.all(
    Array.from({ length: Math.ceil(totalCount / firstPageDocuments.length) - 1 }, (_, index) =>
      fetchSinglePage(index + 1)
    )
  )
  return [...firstPageDocuments, ...additionalPages.flatMap((response) => response.documents)]
}

export default fetchAllPages
