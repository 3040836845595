import { com, Nullable } from '@eidu/entity'
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import TextValue = com.eidu.sharedlib.entity.field.TextValue
import NumberValue = com.eidu.sharedlib.entity.field.NumberValue
import ReferenceValue = com.eidu.sharedlib.entity.field.ReferenceValue
import { fieldValueFromString } from './FieldValueUtil'
import requireSuccess from '../../util/requireSuccess'
import EqualityHashMap from '../../util/EqualityHashMap'

type DraftFieldValue = {
  fieldId: FieldId
  type: FieldType
  value: string
}

export const fieldValueToString = (fieldValue: Nullable<FieldValue>): string => {
  if (fieldValue === null) return ''
  else if (fieldValue instanceof TextValue) return fieldValue.value
  else if (fieldValue instanceof NumberValue) return fieldValue.value.toString()
  else if (fieldValue instanceof ReferenceValue) return fieldValue.value.asString()
  else throw new Error(`Unsupported field value type: ${fieldValue}`)
}

export const draftFieldValueToFieldValue = (draft: DraftFieldValue): FieldValue =>
  requireSuccess(fieldValueFromString(draft.value, draft.type))

export const draftFieldValuesToFieldValues = (draft: readonly DraftFieldValue[]): Map<FieldId, FieldValue> =>
  new EqualityHashMap(draft.map((it) => [it.fieldId, draftFieldValueToFieldValue(it)]))

export const fieldValueToDraftFieldValue = (fieldId: FieldId, fieldValue: Nullable<FieldValue>): DraftFieldValue => {
  if (fieldValue === null) return { fieldId, type: FieldType.Null, value: '' }
  else if (fieldValue instanceof TextValue) return { fieldId, type: FieldType.Text, value: fieldValue.value }
  else if (fieldValue instanceof NumberValue)
    return { fieldId, type: FieldType.Number, value: fieldValue.value.toString() }
  else if (fieldValue instanceof ReferenceValue)
    return { fieldId, type: FieldType.Reference, value: fieldValue.value.asString() }
  else throw new Error(`Unsupported field value type: ${fieldValue}`)
}

export default DraftFieldValue
