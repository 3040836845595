import { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { EditNote, List, Settings } from '@mui/icons-material'
import { Alert, AlertTitle, Button, Collapse } from '@mui/material'
import LoadingOverlay from '../components/LoadingOverlay'
import DrawerScaffold from '../components/DrawerScaffold'
import SnackBarProvider from '../components/SnackBarProvider'
import { EntityTypesContext } from '../io/context/EntityTypes'

const LandingPage = () => {
  const entityTypeRepository = useContext(EntityTypesContext)

  useEffect(() => {
    entityTypeRepository.getAll().then()
  }, [])

  const entityTypes = entityTypeRepository.useSortedEntityTypes() ?? []

  const error = entityTypeRepository.useError()
  const loading = entityTypeRepository.useLoading()

  return (
    <SnackBarProvider>
      <Collapse in={!!error}>
        <Alert
          variant="filled"
          severity="error"
          sx={{ margin: 2 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={async () => {
                await entityTypeRepository.clearError()
                await entityTypeRepository.refreshAll()
              }}
            >
              Refresh
            </Button>
          }
        >
          <AlertTitle>Failed to load entities. Please try again.</AlertTitle>
          <div>{String(error)}</div>
        </Alert>
      </Collapse>
      {!error && (
        <DrawerScaffold
          itemGroups={[
            [
              {
                icon: <EditNote />,
                text: 'Configure forms',
                key: 'configure-forms',
                path: `/forms`,
              },
            ],
            [
              {
                icon: <Settings />,
                text: 'Configure entity types',
                key: 'configure-entity-types',
                path: `/entity-types`,
              },
            ],
            entityTypes.map(({ id, name }) => ({
              icon: <List />,
              text: name,
              key: `type-${id.asString()}`,
              path: `/entities/${id.asString()}`,
            })),
          ]}
        >
          <Outlet />
        </DrawerScaffold>
      )}
      <LoadingOverlay isOpen={!error && (loading || !entityTypes)} />
    </SnackBarProvider>
  )
}

export default LandingPage
