import './App.css'
import { Stack, Toolbar } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import TopAppBar from './components/TopAppBar'
import { EntityTypesProvider } from './io/context/EntityTypes'
import LandingPage from './pages/LandingPage'

const App = () => {
  const { loginInProgress, tokenData, logOut } = useContext<IAuthContext>(AuthContext)

  const accessTokenExpired = tokenData?.exp === undefined || tokenData.exp * 1000 < Date.now()

  const appContent = () => {
    if (loginInProgress || accessTokenExpired) return null
    else
      return (
        <Stack className="app">
          <TopAppBar signOut={logOut} />
          <Toolbar />
          <Stack flex={1}>
            <LandingPage />
          </Stack>
        </Stack>
      )
  }

  return <EntityTypesProvider>{appContent()}</EntityTypesProvider>
}

export default App
