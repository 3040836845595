import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId

type GetEntityTypeParameters = { id: EntityTypeId; accessToken: string }

const getEntityType = ({ id, accessToken }: GetEntityTypeParameters): Promise<EntityType> =>
  fetch(`${apiBaseUrl}/types/${id.asString()}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then(async (response) => {
    if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from getEntityType endpoint`)
    return EntityType.fromJsonString(await response.text())
  })

export default getEntityType
