import { com, kotlin, Nullable } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import serializeToJsonString = com.eidu.sharedlib.entity.util.serializeToJsonString
import PatchEntityRequest = com.eidu.sharedlib.entity.api.entities.PatchEntityRequest
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import KtMap = kotlin.collections.KtMap
import EntityId = com.eidu.sharedlib.entity.EntityId

type PatchEntityParameters = {
  id: EntityId
  valuesByFieldId: Map<FieldId, Nullable<FieldValue>>
  accessToken: string
}

const patchEntity = async ({ id, valuesByFieldId, accessToken }: PatchEntityParameters) => {
  if (valuesByFieldId.size === 0) return

  const response = await fetch(`${apiBaseUrl}/entities/${id.asString()}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
    body: serializeToJsonString(new PatchEntityRequest(KtMap.fromJsMap(valuesByFieldId))),
    method: 'PATCH',
  })
  if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from patchEntity endpoint`)
}

export default patchEntity
