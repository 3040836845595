import { Box, Checkbox, Chip, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React from 'react'

export type MultiSelectInputProps<T> = {
  available: readonly { [label: string]: T }[]
  selected: readonly T[]
  setSelected: (selected: readonly T[]) => void
  label?: string
  labelId?: string
  disabled?: boolean
  error?: boolean
  sx?: React.CSSProperties
}

const getItemLabel = <T extends unknown>(item: { [label: string]: T }) => Object.keys(item)[0]
const getItemValue = <T extends unknown>(item: { [label: string]: T }) => item[Object.keys(item)[0]]

const MultiSelectInput = <T extends unknown>({
  available,
  selected,
  setSelected,
  label,
  labelId,
  disabled,
  error,
  sx,
}: MultiSelectInputProps<T>) => {
  const selectedIndices = selected
    .filter((value) => available.some((item) => getItemValue(item) === value))
    .map((value) => available.findIndex((item) => getItemValue(item) === value))

  const handleSelectChange = (event: SelectChangeEvent<number[]>) => {
    const newSelectedIndices = (event.target.value as number[]).sort()
    setSelected(newSelectedIndices.map((index) => getItemValue(available[index])))
  }

  const removeItem = (index: number) => {
    const newSelectedIndices = selectedIndices.filter((i) => i !== index).sort()
    setSelected(newSelectedIndices.map((i) => getItemValue(available[i])))
  }

  return (
    <Select
      multiple
      value={selectedIndices}
      label={label}
      labelId={labelId}
      disabled={disabled}
      error={error}
      onChange={handleSelectChange}
      sx={sx}
      renderValue={(indices) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {indices.map((index) => (
            <Chip
              key={index}
              label={getItemLabel(available[index])}
              onDelete={() => removeItem(index)}
              onMouseDown={(event) => {
                event.stopPropagation()
              }}
            />
          ))}
        </Box>
      )}
    >
      {available.map((value, index) => (
        <MenuItem key={JSON.stringify(value)} value={index}>
          <Checkbox checked={selectedIndices.indexOf(index) > -1} />
          <ListItemText primary={getItemLabel(value)} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default MultiSelectInput
