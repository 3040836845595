import ReactCodeMirror, { DecorationSet, EditorState, EditorView } from '@uiw/react-codemirror'
import { InputBaseComponentProps, TextField } from '@mui/material'
import React from 'react'
import FieldData from '../../../domain/entity/FieldData'
import ValidFieldNamesContext from './ValidFieldNamesContext'
import highlightFieldReferences from './highlightFieldReferences'

const LabelFormatInputComponent = React.forwardRef<HTMLInputElement, InputBaseComponentProps>(
  ({ value, onChange, disabled }: InputBaseComponentProps, _) => {
    const singleLineExtension = EditorState.transactionFilter.of((transaction) => {
      if (transaction.newDoc.lines > 1) {
        return []
      }
      return transaction
    })

    const validFieldNames = React.useContext(ValidFieldNamesContext)

    const highlightExtension = EditorView.decorations.of(
      (view: EditorView): DecorationSet => highlightFieldReferences(view, validFieldNames)
    )

    const theme = EditorView.theme({
      '&': {
        paddingTop: '1.2ex',
        paddingBottom: '1.2ex',
        paddingLeft: '0.8ex',
        paddingRight: '0.8ex',
      },
      '&.cm-editor.cm-focused': {
        outline: 'none',
      },
    })

    return (
      <ReactCodeMirror
        style={{ display: 'block', width: '100%' }}
        value={value}
        onChange={(it) => {
          if (onChange !== undefined) onChange({ target: { value: it } } as React.ChangeEvent<HTMLInputElement>)
        }}
        basicSetup={{
          foldGutter: false,
          lineNumbers: false,
          highlightActiveLine: false,
        }}
        indentWithTab={false}
        extensions={[theme, singleLineExtension, highlightExtension]}
        editable={!disabled}
      />
    )
  }
)

export type LabelFormatInputProps = {
  title: string
  label: string
  setLabel: (label: string) => void
  availableFields: readonly FieldData[]
  errors: string | undefined
  editable: boolean
}

const LabelInput = ({ title, label, setLabel, availableFields, errors, editable }: LabelFormatInputProps) => {
  const validFieldNames = availableFields.map((it) => it.field.name)
  return (
    <ValidFieldNamesContext.Provider value={validFieldNames}>
      <TextField
        value={label}
        label={title}
        onChange={(event) => {
          setLabel(event.target.value)
        }}
        InputProps={{
          inputComponent: LabelFormatInputComponent,
          fullWidth: true,
        }}
        disabled={!editable}
        error={!!errors}
        helperText={errors}
      />
    </ValidFieldNamesContext.Provider>
  )
}

export default LabelInput
