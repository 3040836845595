import { clamp } from 'lodash'
import apiBaseUrl from './baseUrl'
import maxPageSize from './maxPageSize'

import PaginatedResponse from '../PaginatedResponse'
import FormConfiguration from '../../domain/form/FormConfiguration'
import bearerTokenHeader from '../authorization/bearerTokenHeader'

type GetFormConfigurationsParameters = {
  organizationId: string
  pageIndex: number
  pageSize: number
  accessToken: string
}

const getFormConfigurations = ({
  organizationId,
  pageIndex,
  pageSize,
  accessToken,
}: GetFormConfigurationsParameters): Promise<PaginatedResponse<FormConfiguration>> =>
  fetch(
    `${apiBaseUrl}/organizations/${organizationId}/forms?pageIndex=${clamp(pageIndex, 0, Infinity)}&pageSize=${clamp(pageSize, 1, maxPageSize)}`,
    {
      headers: bearerTokenHeader(accessToken),
    }
  ).then((response) => {
    if (response.status === 200) return response.json()
    else throw Error(`Unexpected response code ${response.status} from getFormConfigurations endpoint`)
  })

export default getFormConfigurations
