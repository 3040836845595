import { Comparable } from './Comparable'

const sortedBy = <T>(items: readonly T[], key: (item: T) => Comparable): T[] =>
  items.toSorted((a, b) => {
    const keyA = key(a)
    const keyB = key(b)
    return keyA === keyB ? 0 : keyA > keyB ? 1 : -1
  })

export default sortedBy
