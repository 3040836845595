import { useEffect, useState } from 'react'

export type ErrorListener = (error: unknown) => Promise<void>

class ErrorState {
  private error: unknown = undefined

  private errorListeners: ErrorListener[] = []

  getError = () => this.error

  async handleError(error: unknown) {
    this.error = this.error || error
    await this.notifyErrorListeners(error)
  }

  async clearError() {
    this.error = undefined
    await this.notifyErrorListeners(undefined)
  }

  addErrorListener(listener: ErrorListener) {
    this.errorListeners.push(listener)
  }

  removeErrorListener(listener: ErrorListener) {
    this.errorListeners = this.errorListeners.filter((it) => it !== listener)
  }

  async notifyErrorListeners(error: unknown) {
    await Promise.all(this.errorListeners.map((listener) => listener(error)))
  }

  useError(deps: readonly unknown[] = []) {
    const [error, setError] = useState(this.error)
    useEffect(() => {
      setError(this.getError())
      const listener: ErrorListener = async (value) => setError(value)
      this.addErrorListener(listener)
      return () => this.removeErrorListener(listener)
    }, deps)
    return error
  }
}

export default ErrorState
