import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import JobStatus = com.eidu.sharedlib.entity.job.JobStatus

const getJobStatus = (jobId: string, accessToken: string): Promise<JobStatus> =>
  fetch(`${apiBaseUrl}/job-status/${jobId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then(async (response): Promise<JobStatus> => {
    if (response.status !== 200) throw Error(`Unexpected response code ${response.status} from job status endpoint`)

    return JobStatus.fromJsonString(await response.text())
  })

export default getJobStatus
