import actions from './Action'
import { Availability } from './Availability'

const defaultAvailability: Availability = {
  actions,
  role: { type: 'Any' },
  program: { type: 'Any' },
}

export default defaultAvailability
