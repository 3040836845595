import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { ReactNode } from 'react'

export type FormAvailabilityInputProps = {
  values: readonly string[]
  displayValues: readonly string[]
  options: readonly string[]
  label: string
  labelId: string
  readOnly: boolean
  onChange: (event: SelectChangeEvent<readonly string[]>) => void
  renderItemText: (text: string) => ReactNode
  errorMessageWhenEmpty: string
}

const FormAvailabilityInput = ({
  values,
  displayValues,
  options,
  label,
  labelId,
  readOnly,
  onChange,
  renderItemText,
  errorMessageWhenEmpty,
}: FormAvailabilityInputProps) => (
  <FormControl>
    <InputLabel id={labelId}>{label}</InputLabel>
    <Select
      multiple
      required
      displayEmpty
      label={label}
      labelId={labelId}
      value={values}
      disabled={readOnly}
      error={!values.length}
      onChange={onChange}
      renderValue={(selected) =>
        selected.length ? (
          <Box className="inputChipContainer">
            {displayValues.map((value) => (
              <Chip key={value} label={renderItemText(value)} />
            ))}
          </Box>
        ) : (
          <Typography color="error">{errorMessageWhenEmpty}</Typography>
        )
      }
    >
      {options.map((value) => (
        <MenuItem key={value} value={value}>
          <Checkbox checked={values.includes(value)} />
          <ListItemText primary={renderItemText(value)} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default FormAvailabilityInput
