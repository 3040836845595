import { Availability } from './availability/Availability'

export const hasTitleError = (title: string | undefined) => title?.trim() === ''
export const hasAvailabilityError = (availability: Availability) =>
  !(
    availability.actions.length &&
    (availability.role.type === 'Any' || availability.role.names.length) &&
    (availability.program.type === 'Any' || availability.program.names.length)
  )

const hasConfigurationErrors = (title: string | undefined, availabilities: readonly Availability[] | undefined) =>
  hasTitleError(title) || availabilities?.some((availability) => hasAvailabilityError(availability))

export default hasConfigurationErrors
