import { Availability } from './Availability'

const areEqual = (availability1: Availability, availability2: Availability) => {
  if (availability1.actions.length !== availability2.actions.length) return false
  if (availability1.actions.some((action, index) => action !== availability2.actions[index])) return false

  if (availability1.role.type !== availability2.role.type) return false
  if (
    availability1.role.type !== 'Any' &&
    availability1.role.names.some(
      (role, index) => availability2.role.type === 'Any' || role !== availability2.role.names[index]
    )
  )
    return false

  if (availability1.program.type !== availability2.program.type) return false
  return (
    availability1.program.type === 'Any' ||
    !availability1.program.names.some(
      (program, index) => availability2.program.type === 'Any' || program !== availability2.program.names[index]
    )
  )
}

export default areEqual
