import { useContext, useEffect, useMemo } from 'react'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import { com } from '@eidu/entity'
import EntityRepository, { setGlobalToken } from './EntityRepository'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId

type UseEntityRepositoryParams = {
  typeId: EntityTypeId | undefined
  types: ReadonlyMap<EntityTypeId, EntityType>
  searchQuery?: string
  pageSize?: number
}

const useEntityRepository = (
  { typeId, types, searchQuery, pageSize = 10 }: UseEntityRepositoryParams,
  deps?: readonly unknown[]
): EntityRepository => {
  const { token } = useContext<IAuthContext>(AuthContext)
  useEffect(() => {
    setGlobalToken(token)
  }, [token])

  return useMemo(
    () => new EntityRepository({ typeId, types, searchQuery, pageSize }),
    [types, searchQuery, ...(deps ?? [])]
  )
}

export default useEntityRepository
