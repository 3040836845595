import { AvailabilityParameter } from './AvailabilityParameter'

const updateAvailabilityParameter = (
  parameter: AvailabilityParameter,
  options: readonly string[],
  values: readonly string[]
): AvailabilityParameter => {
  switch (parameter.type) {
    case 'Any':
      return values.includes('All')
        ? {
            type: 'OneOf',
            names: options
              .slice(1)
              .filter((name) => values.includes(name))
              .map((name) => (name === 'Unknown' ? null : name)),
          }
        : { type: 'OneOf', names: [] }
    case 'OneOf':
      return values.includes('All') || options.slice(1).every((option) => values.includes(option))
        ? { type: 'Any' }
        : {
            type: 'OneOf',
            names: (values as readonly string[]).map((name) => (name === 'Unknown' ? null : name)),
          }
    default: {
      const unused: never = parameter
      return { type: 'Any' }
    }
  }
}

export default updateAvailabilityParameter
