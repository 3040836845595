import { com } from '@eidu/entity'
import Entity = com.eidu.sharedlib.entity.Entity
import EntityId = com.eidu.sharedlib.entity.EntityId
import { isNotUndefinedOrNull } from '../../util/predicates'
import ReferenceValue = com.eidu.sharedlib.entity.field.ReferenceValue

const getReferencedEntityIds = (entity: Entity): EntityId[] =>
  Array.from(entity.valuesByFieldId.asJsReadonlyMapView())
    .map(([, field]) => (field instanceof ReferenceValue ? field.value : null))
    .filter(isNotUndefinedOrNull)

export default getReferencedEntityIds
