import { useEffect, useState } from 'react'

export type LoadingListener = (loading: boolean) => Promise<void>

class LoadingState {
  private loadingCount: number = 0

  private loadingListeners: LoadingListener[] = []

  isLoading = () => this.loadingCount > 0

  addLoadingListener(listener: LoadingListener) {
    this.loadingListeners.push(listener)
  }

  removeLoadingListener(listener: LoadingListener) {
    this.loadingListeners = this.loadingListeners.filter((it) => it !== listener)
  }

  async beginLoading() {
    this.loadingCount += 1
    await this.notifyLoadingListeners()
  }

  async endLoading() {
    this.loadingCount -= 1
    await this.notifyLoadingListeners()
  }

  private notifyLoadingListeners = async () => {
    const loading = this.isLoading()
    return Promise.all(this.loadingListeners.map((listener) => listener(loading)))
  }

  useLoading(deps: readonly unknown[] = []): boolean {
    const [loading, setLoading] = useState(this.isLoading())
    useEffect(() => {
      setLoading(this.isLoading())
      const listener: LoadingListener = async (value) => setLoading(value)
      this.addLoadingListener(listener)
      return () => this.removeLoadingListener(listener)
    }, deps)
    return loading
  }
}

export default LoadingState
