import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'

type SnackbarSeverity = 'info' | 'success' | 'error'

type SnackBarContextActions = {
  showSnackBar: (text: string, snackbarSeverity: SnackbarSeverity) => void
}

const SnackBarContext = createContext({} as SnackBarContextActions)

interface SnackBarContextProviderProps {
  children: ReactNode
}

const SnackBarProvider: FC<SnackBarContextProviderProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<SnackbarSeverity>('info')

  const handleClose = () => setOpen(false)

  const showSnackBar = useMemo(
    () => ({
      showSnackBar: (text: string, severity: SnackbarSeverity) => {
        setMessage(text)
        setSnackbarSeverity(severity)
        setOpen(true)
      },
    }),
    []
  )

  return (
    <SnackBarContext.Provider value={showSnackBar}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
        sx={{ marginTop: 8 }}
      >
        <Alert variant="filled" onClose={handleClose} severity={snackbarSeverity}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  )
}

export const useSnackBar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext)

  if (!context) {
    throw new Error('useSnackBar must be used within a SnackBarProvider')
  }

  return context
}

export default SnackBarProvider
