import apiBaseUrl from './baseUrl'
import FormConfiguration from '../../domain/form/FormConfiguration'
import bearerTokenHeader from '../authorization/bearerTokenHeader'

type GetFormConfigurationParameters = { formId: string; organizationId: string; accessToken: string }

const getFormConfiguration = ({
  formId,
  organizationId,
  accessToken,
}: GetFormConfigurationParameters): Promise<FormConfiguration> =>
  fetch(`${apiBaseUrl}/organizations/${organizationId}/forms/${formId}`, {
    headers: bearerTokenHeader(accessToken),
  }).then(async (it) => {
    const response = await it.json()
    if (it.status === 200) return response.configuration
    else throw Error(`Unexpected response code ${it.status} from getFormConfiguration endpoint`)
  })

export default getFormConfiguration
