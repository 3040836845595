import { Availability } from '../Availability'
import Program from '../../../user/Program'
import areEqual from '../areEqual'
import defaultAvailability from '../defaultAvailability'
import normalizedAvailability from './normalizedAvailability'

const prepareForSaving = (
  availabilities: readonly Availability[],
  programs: readonly Program[],
  organizationId: string
) =>
  (availabilities.some((availability) => areEqual(defaultAvailability, availability))
    ? [defaultAvailability]
    : availabilities
  ).map((availability) => normalizedAvailability(availability, programs, organizationId))

export default prepareForSaving
