declare global {
  interface Object {
    let<T, R>(this: T, block: (it: T) => R): R
    also<T, R>(this: T, block: (it: T) => R): T
    run<T, R>(this: T, block: (it: T) => R): R
    applyScope<T, R>(this: T, block: (it: T) => R): T
  }
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, 'let', {
  value<T, R>(this: T, block: (arg: T) => R) {
    return block(this)
  },
})

// eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, 'also', {
  value<T, R>(this: T, block: (arg: T) => R) {
    block(this)
    return this
  },
})

// eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, 'run', {
  value<T, R>(this: T, block: (arg: T) => R) {
    return block.apply(this, [this])
  },
})

// eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, 'applyScope', {
  value<T, R>(this: T, block: (arg: T) => R) {
    block.apply(this, [this])
    return this
  },
})

export {}
