/* eslint-disable no-console */

import * as Sentry from '@sentry/react'
import { SeverityLevel } from '@sentry/react'

const stage = import.meta.env.VITE_STAGE
const nonDebugStages: readonly string[] = ['prod', 'demo']

const logToSentry = import.meta.env.MODE === 'production'

export const logMessage = (message: string, severityLevel?: SeverityLevel) => {
  if (!nonDebugStages.includes(stage) && (severityLevel === 'fatal' || severityLevel === 'error'))
    console.error(message)
  else if (!nonDebugStages.includes(stage)) console.log(message)
  if (logToSentry) Sentry.captureMessage(message, severityLevel)
}

export const logException = (exception: unknown) => {
  if (!nonDebugStages.includes(stage)) console.error(exception)
  if (logToSentry) Sentry.captureException(exception)
}
