import { AppBar, Button, Toolbar, Typography } from '@mui/material'

export type TopAppBarProps = {
  title?: string
  signOut?: () => void
}

const TopAppBar = ({ title, signOut }: TopAppBarProps) => (
  <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <Toolbar>
      <img src="logo192.png" className="appBarIcon" alt="" />
      <Typography variant="h6" className="appBarTitle">
        {title || 'EIDU'}
      </Typography>
      {signOut && (
        <Button variant="text" color="inherit" onClick={signOut}>
          Log out
        </Button>
      )}
    </Toolbar>
  </AppBar>
)

export default TopAppBar
