import supportStaffServiceBaseUrl from '../supportStaffServiceBaseUrl'
import Program from '../../domain/user/Program'
import bearerTokenHeader from '../authorization/bearerTokenHeader'

type GetProgramsParameters = {
  organizationId: string
  accessToken: string
}

type GetProgramsResponse = { programs: readonly Program[] }

const getPrograms = ({ organizationId, accessToken }: GetProgramsParameters): Promise<readonly Program[]> =>
  fetch(`${supportStaffServiceBaseUrl}/organizations/${organizationId}/programs`, {
    headers: bearerTokenHeader(accessToken),
  }).then(async (it) => {
    if (it.status === 200) {
      const response: GetProgramsResponse = await it.json()
      return response.programs
    } else throw Error(`Unexpected response code ${it.status} from getPrograms endpoint`)
  })

export default getPrograms
