import areEqual from './availability/areEqual'
import FormConfiguration from './FormConfiguration'
import { Availability } from './availability/Availability'

const hasConfigurationChanges = (
  currentConfiguration: FormConfiguration,
  title: string | undefined,
  availabilities: readonly Availability[] | undefined
) =>
  title !== currentConfiguration.title ||
  availabilities?.length !== currentConfiguration.availability.length ||
  availabilities?.some((availability, index) => !areEqual(availability, currentConfiguration.availability[index]))

export default hasConfigurationChanges
