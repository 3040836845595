export const requireNotUndefinedOrNull = <T>(
  value: T | null | undefined,
  message: string = 'Value cannot be undefined or null'
): T => {
  if (value === undefined || value === null) {
    throw new Error(message)
  }
  return value
}

export const requireNotNull = <T>(value: T | null, message: string = 'Value cannot be null'): T => {
  if (value === null) {
    throw new Error(message)
  }
  return value
}

export const requireNotUndefined = <T>(value: T | undefined, message: string = 'Value cannot be undefined'): T => {
  if (value === undefined) {
    throw new Error(message)
  }
  return value
}
