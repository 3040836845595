import { Add, Delete } from '@mui/icons-material'
import { IconButton, SelectChangeEvent, Stack, Typography } from '@mui/material'
import availabilityParameterValues from '../../../domain/form/availability/parameter/availabilityParameterValues'
import roleOptions from '../../../domain/form/availability/parameter/roleOptions'
import availabilityParameterDisplayValues from '../../../domain/form/availability/parameter/availabilityParameterDisplayValues'
import programOptions from '../../../domain/form/availability/parameter/programOptions'
import actionOptions from '../../../domain/form/availability/Action'
import updateAvailabilityParameter from '../../../domain/form/availability/parameter/updateAvailabilityParameter'
import FormAvailabilityInput from './FormAvailabilityInput'
import labelRole from '../../../domain/user/labelRole'
import areEqual from '../../../domain/form/availability/areEqual'
import defaultAvailability from '../../../domain/form/availability/defaultAvailability'
import { Availability } from '../../../domain/form/availability/Availability'
import Program from '../../../domain/user/Program'

export type FormAvailabilityRowProps = {
  availability: Availability
  readOnly: boolean
  idPrefix: string
  programs: readonly Program[]
  organizationId: string
  onAdd: () => void
  onChange: (updatedAvailability: Availability) => void
  onDelete: () => void
}

const FormAvailabilityRow = ({
  availability,
  readOnly,
  idPrefix,
  programs,
  organizationId,
  onAdd,
  onChange,
  onDelete,
}: FormAvailabilityRowProps) => {
  const { actions, role, program } = availability

  const roleValues = availabilityParameterValues(role, roleOptions(organizationId))
  const roleDisplayValues = availabilityParameterDisplayValues(role.type, roleValues)
  const programValues = availabilityParameterValues(program, programOptions(programs))
  const programDisplayValues = availabilityParameterDisplayValues(program.type, programValues)

  const onActionsChanged = (event: SelectChangeEvent<readonly string[]>) =>
    onChange({
      actions: actionOptions.filter((action) => event.target.value.includes(action)),
      role,
      program,
    })
  const onRolesChanged = (event: SelectChangeEvent<readonly string[]>) =>
    onChange({
      actions,
      role: updateAvailabilityParameter(
        role,
        roleOptions(organizationId),
        Array.isArray(event.target.value) ? event.target.value : [event.target.value]
      ),
      program,
    })
  const onProgramsChanged = (event: SelectChangeEvent<readonly string[]>) =>
    onChange({
      actions,
      role,
      program: updateAvailabilityParameter(
        program,
        programOptions(programs),
        Array.isArray(event.target.value) ? event.target.value : [event.target.value]
      ),
    })

  const renderItemTextEmphasized = (
    emphasizedOptions: readonly string[],
    itemText: string,
    renderNonEmphasized: (itemText: string) => string
  ) => (emphasizedOptions.includes(itemText) ? <em>{itemText}</em> : renderNonEmphasized(itemText))

  return (
    <Stack>
      <Stack direction="row" spacing={1}>
        <FormAvailabilityInput
          values={actions}
          displayValues={actions}
          options={actionOptions}
          label="Actions"
          labelId={`${idPrefix}-actions`}
          readOnly={readOnly}
          onChange={onActionsChanged}
          renderItemText={(text) => text}
          errorMessageWhenEmpty="Please select an action"
        />
        <FormAvailabilityInput
          values={roleValues}
          displayValues={roleDisplayValues}
          options={roleOptions(organizationId)}
          label="Roles"
          labelId={`${idPrefix}-roles`}
          readOnly={readOnly}
          onChange={onRolesChanged}
          renderItemText={(text) =>
            renderItemTextEmphasized(['All', 'Unknown'], text, (it) => labelRole(it, organizationId))
          }
          errorMessageWhenEmpty="Please select a role"
        />
        <FormAvailabilityInput
          values={programValues}
          displayValues={programDisplayValues}
          options={programOptions(programs)}
          label="Programmes"
          labelId={`${idPrefix}-programs`}
          readOnly={readOnly}
          onChange={onProgramsChanged}
          renderItemText={(text) =>
            renderItemTextEmphasized(
              ['All', 'Unknown'],
              text,
              (itemText) => programs.find((it) => it.name === itemText)?.title ?? itemText
            )
          }
          errorMessageWhenEmpty="Please select a programme"
        />
        {!readOnly && (
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        )}
        <IconButton onClick={onAdd}>
          <Add color="primary" />
        </IconButton>
      </Stack>
      {areEqual(defaultAvailability, availability) && (
        <Typography sx={{ padding: 1 }}>
          <strong>
            This makes the form available to everyone. If that is not intended, please change or remove this item.
          </strong>
        </Typography>
      )}
    </Stack>
  )
}

export default FormAvailabilityRow
