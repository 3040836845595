import apiBaseUrl from './baseUrl'
import bearerTokenHeader from '../authorization/bearerTokenHeader'

type GetFormContentTextParameters = {
  formContentId: string
  organizationId: string
  useYamlFormat: boolean
  accessToken: string
}

const getFormContentText = ({
  formContentId,
  organizationId,
  useYamlFormat,
  accessToken,
}: GetFormContentTextParameters): Promise<string> =>
  fetch(`${apiBaseUrl}/organizations/${organizationId}/form-contents-text/${formContentId}`, {
    headers: useYamlFormat
      ? { ...bearerTokenHeader(accessToken), Accept: 'application/yaml' }
      : bearerTokenHeader(accessToken),
  }).then(async (it) => {
    const response = await it.text()
    if (it.status === 200) return response
    else throw Error(`Unexpected response code ${it.status} from getFormConfiguration endpoint`)
  })

export default getFormContentText
