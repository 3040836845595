import apiBaseUrl from './baseUrl'
import bearerTokenHeader from '../authorization/bearerTokenHeader'

type PostFormContentTextParameters = {
  organizationId: string
  accessToken: string
  useYamlFormat: boolean
  formContentText: string
}

const postFormContentText = async ({
  organizationId,
  accessToken,
  useYamlFormat,
  formContentText,
}: PostFormContentTextParameters): Promise<string> => {
  const response = await fetch(`${apiBaseUrl}/organizations/${organizationId}/form-contents-text`, {
    method: 'POST',
    headers: useYamlFormat
      ? { ...bearerTokenHeader(accessToken), 'Content-Type': 'application/yaml' }
      : bearerTokenHeader(accessToken),
    body: useYamlFormat ? formContentText : JSON.stringify({ formContentText }),
  })
  const responseJson = await response.json()
  if (response.status !== 200) throw Error(responseJson.error.message)

  return responseJson.contentId
}

export default postFormContentText
