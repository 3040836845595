import { AvailabilityParameter } from './AvailabilityParameter'

const availabilityParameterValues = (parameter: AvailabilityParameter, options: readonly string[]) => {
  switch (parameter.type) {
    case 'Any':
      return options
    case 'OneOf':
      return options
        .slice(1)
        .filter((name) => (name === 'Unknown' ? parameter.names.includes(null) : parameter.names.includes(name)))
    default: {
      const unused: never = parameter
      return options
    }
  }
}

export default availabilityParameterValues
