import { com, kotlin } from '@eidu/entity'
import FieldData from '../../../domain/entity/FieldData'
import KtList = kotlin.collections.KtList
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import NamedLabelFormat = com.eidu.sharedlib.entity.label.NamedLabelFormat
import EntityLabel = com.eidu.sharedlib.entity.label.EntityLabel
import EntityLabelLine = com.eidu.sharedlib.entity.label.EntityLabelLine

export const labelLineToString = (
  { format, argumentFields }: EntityLabelLine,
  availableFields: readonly FieldData[]
): string => {
  const fieldNames = argumentFields
    .asJsReadonlyArrayView()
    .map((id) => availableFields.find((field) => id.equals(field.field.id))?.field?.name ?? id.asString())
  return format.toNamedLabelFormat(KtList.fromJsArray(fieldNames)).pattern
}

export const stringToLabelLine = (
  label: string,
  availableFields: readonly FieldData[]
): EntityLabelLine | undefined => {
  const namedFormat = new NamedLabelFormat(label)
  const argumentFieldsOrUndefined = namedFormat.variableNames
    .asJsReadonlyArrayView()
    .map((it) => availableFields.find((field) => field.field.name === it)?.field.id)
  const argumentFields = argumentFieldsOrUndefined.filter((it): it is FieldId => it !== undefined)
  if (argumentFields.length !== argumentFieldsOrUndefined.length) return undefined

  return new EntityLabelLine(namedFormat.indexedFormat, KtList.fromJsArray(argumentFields))
}

export const createEntityLabel = ({
  primaryLabel,
  secondaryLabel,
  fields,
}: {
  primaryLabel?: string
  secondaryLabel?: string
  fields: readonly FieldData[]
}): EntityLabel | undefined => {
  const primary = primaryLabel?.let((it) => stringToLabelLine(it, fields))
  const secondary = secondaryLabel?.let((it) => stringToLabelLine(it, fields))

  if (primary === undefined) return undefined
  else return new EntityLabel(primary, secondary)
}
