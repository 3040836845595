import FormConfiguration from '../../domain/form/FormConfiguration'
import apiBaseUrl from './baseUrl'
import bearerTokenHeader from '../authorization/bearerTokenHeader'

type PutFormConfigurationParameters = {
  organizationId: string
  accessToken: string
  formConfiguration: FormConfiguration
}

const putFormConfiguration = async ({
  organizationId,
  accessToken,
  formConfiguration,
}: PutFormConfigurationParameters) => {
  const response = await fetch(`${apiBaseUrl}/organizations/${organizationId}/forms/${formConfiguration.id}`, {
    method: 'PUT',
    headers: bearerTokenHeader(accessToken),
    body: JSON.stringify({ configuration: formConfiguration }),
  })
  if (response.status !== 200)
    throw Error(`Unexpected response code ${response.status} from putFormConfiguration endpoint`)
}

export default putFormConfiguration
