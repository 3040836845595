import { ListItemButton } from '@mui/material'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

export type ListItemButtonWithNavigationProps = {
  path: string
  children: ReactNode
}

const ListItemButtonWithNavigation = ({ path, children }: ListItemButtonWithNavigationProps) => {
  const navigate = useNavigate()

  return (
    <ListItemButton
      href={path}
      onClick={(event) => {
        event.preventDefault()
        navigate(path)
      }}
    >
      {children}
    </ListItemButton>
  )
}

export default ListItemButtonWithNavigation
