import { com, kotlin } from '@eidu/entity'
import DraftFieldValue, { draftFieldValuesToFieldValues } from '../../domain/entity/DraftFieldValue'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityToCreate = com.eidu.sharedlib.entity.api.entities.EntityToCreate
import KtMap = kotlin.collections.KtMap

const createEntityToCreate = (typeId: EntityTypeId, fieldValues: DraftFieldValue[]) =>
  new EntityToCreate(typeId, KtMap.fromJsMap(draftFieldValuesToFieldValues(fieldValues)))

export default createEntityToCreate
