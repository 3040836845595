import { useRouteError } from 'react-router-dom'
import { Typography } from '@mui/material'

const ErrorPage = () => {
  // @ts-expect-error useRouteError() is typed as unknown
  const error: { statusCode?: number; statusText?: string; message?: string } = useRouteError()
  return error.statusCode === 404 ? (
    <Typography variant="h6">Page not found</Typography>
  ) : (
    <div>
      <Typography variant="h6">Internal error</Typography>
      <Typography>An unexpected error has occurred: {error.statusText || error.message || 'Unknown error'}</Typography>
    </div>
  )
}

export default ErrorPage
