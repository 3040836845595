import { Fragment, ReactNode } from 'react'

import { Box, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Stack, Toolbar } from '@mui/material'
import ListItemButtonWithNavigation from './ListItemButtonWithNavigation'

export type DrawerItem = {
  icon?: ReactNode
  text: string
  key: string
  path: string
}

export type DrawerScaffoldProps = {
  itemGroups: readonly (readonly DrawerItem[])[]
  children: ReactNode
}

const DrawerScaffold = ({ itemGroups, children }: DrawerScaffoldProps) => (
  <Box className="drawerScaffold">
    <Drawer variant="permanent" PaperProps={{ elevation: 2 }} className="drawer">
      <Toolbar />
      {itemGroups.flatMap((items, index) => (
        <Fragment key={`fragment-${items[0]?.key}`}>
          {!!index && <Divider />}
          {items.map(({ icon, text, key, path }) => (
            <List key={`list-${key}`}>
              <ListItem key={`item-${key}`} disablePadding>
                <ListItemButtonWithNavigation path={path}>
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText primary={text} />
                </ListItemButtonWithNavigation>
              </ListItem>
            </List>
          ))}
        </Fragment>
      ))}
    </Drawer>
    <Stack sx={{ flexGrow: 1, overflowX: 'hidden' }}>{children}</Stack>
  </Box>
)

export default DrawerScaffold
